
import { Vue, Component } from 'vue-property-decorator'
import { ProjectDeviceList, SoilHumidityDetail, DeviceInfo } from '@/types/ecologyMonitor'
import { PolygonPathDetail } from '@/types/common'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon, directionTransition } from '@/utils/formatData'

@Component
export default class WeatherMonitor extends Vue {
  // 折叠面板当前展开项
  private activeName = 0
  // 当前地图展示项目
  private projectId = ''
  // 项目列表
  private projectDeviceList: ProjectDeviceList[] = []
  // 当前项目设备列表
  private deviceList = []
  private projectLocation: Array<PolygonPathDetail> = []
  private drawer = false
  private map: AMap.Map | undefined = undefined
  private ploygons: Array<AMap.Polygon> = []
  private markers: Array<AMap.Marker> = []
  private onlineImg = require('@/assets/icon/weatherQuality/icon_map_huanjingjiankong_zaixian.svg')
  private onlineImgCursor = require('@/assets/icon/weatherQuality/icon_map_huanjingjiankong_zaixian_dianji.svg')
  private offImg = require('@/assets/icon/weatherQuality/icon_map_huanjingjiankong_lixian.svg')
  private offImgCursor = require('@/assets/icon/weatherQuality/icon_map_huanjingjiankong_lixian_dianji.svg')

  private drawerData: SoilHumidityDetail | null = null
  private detailInfo: Array<{title: string; value: string; icon: string; status: string}> = []

  private pm25StatusArr = [
    {
      name: '优',
      color: 'rgba(82, 196, 26, 1)'
    },
    {
      name: '良',
      color: 'rgba(248, 221, 0, 1)'
    },
    {
      name: '轻度污染',
      color: 'rgba(255, 193, 23, 1)'
    },
    {
      name: '中度污染',
      color: 'rgba(255, 113, 23, 1)'
    },
    {
      name: '重度污染',
      color: 'rgba(163, 14, 220, 1)'
    },
    {
      name: '严重污染',
      color: 'rgba(132, 2, 71, 1)'
    }
  ]

  get pm25StatusColor () {
    let color = ''
    this.pm25StatusArr.map((item: {name: string;color: string}) => {
      if (this.drawerData && item.name === this.drawerData.pm25Status) {
        color = item.color
      }
    })
    return color
  }

  created () {
    this.getData()
  }

  destroyed () {
    if (this.markers) {
      this.markers.forEach((item: AMap.Marker) => {
        item.off('click', this.drawerOpen)
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', this.drawerClose)
      })
    }
    if (this.map) {
      this.map.off('click', this.drawerClose)
      this.map.destroy()
    }
  }

  // 左侧项目列表
  getData () {
    this.$axios.get(this.$apis.ecologyMonitor.selectProjectWeatherByList).then(res => {
      this.projectDeviceList = res
      if (this.projectDeviceList.length > 0) {
        this.projectId = this.projectDeviceList[0].projectId
        this.getPloygon()
      } else {
        this.$nextTick(() => {
          this.initMap(116.4017, 39.9)
        })
      }
    })
  }

  // 切换项目
  changeProject () {
    const id = this.projectDeviceList[Number(this.activeName)].projectId
    if ((this.activeName || this.activeName === 0) && this.projectId !== id) {
      this.projectId = id
      this.getPloygon()
      this.drawerClose()
    }
  }

  // 获取项目围栏
  getPloygon () {
    this.$axios.get(this.$apis.project.selectYhProjectMapByProjectId, {
      projectId: this.projectId
    }).then((res) => {
      if (res) {
        this.projectLocation = res.locationList || []
        const lng = Number(res.longitude)
        const lat = Number(res.latitude)
        this.$nextTick(() => {
          this.initMap(lng, lat)
        })
      } else {
        this.$message.error('未获取到项目信息')
      }
    })
  }

  getDeviceList () {
    this.$axios.get(this.$apis.ecologyMonitor.selectWeatherInfoByPage, {
      projectId: this.projectId
    }).then(res => {
      this.deviceList = res.weatherInfo.list || []
      this.drawMarker(this.deviceList)
    })
  }

  // 初始化地图
  initMap (lng: number, lat: number) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [lng, lat]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      this.map = map
      this.ploygons = drawPolygon(this.map, this.projectLocation || [], null, this.drawerClose)
      this.getDeviceList()

      // 点击地图关闭抽屉
      this.map.on('click', this.drawerClose)
    })
  }

  // 地图打点
  drawMarker (list: Array<any>) {
    this.markers = []
    if (list && list.length) {
      // 循坏将设备marker添加到地图
      list.forEach((item: SoilHumidityDetail) => {
        if (item.longitude && item.latitude) {
          const icon = this.getIcon(item.deviceStatus, false)
          const marker: any = new AMap.Marker({
            icon: new AMap.Icon({
              // 图标的取图地址
              image: icon
            }),
            offset: new AMap.Pixel(-18, -48),
            position: new AMap.LngLat(Number(item.longitude), Number(item.latitude))
          })
          marker.detail = item
          marker.on('click', this.drawerOpen)
          this.map && this.map.add(marker)
          this.markers.push(marker)
        }
      })
    }
  }

  // 切换设备
  changeDevice (row: DeviceInfo) {
    if (this.map) {
      const e: any = {
        target: {}
      }
      if (row.longitude && row.latitude) {
        e.target = this.map.getAllOverlays('marker').find((item: any) => {
          return item.detail.deviceNumber === row.deviceNumber
        })
        this.map.setCenter([Number(row.longitude), Number(row.latitude)])
      } else {
        this.iconTab()
        e.target = {
          detail: row
        }
      }
      this.drawerOpen(e)
    }
  }

  // 抽屉打开
  drawerOpen (e: any) {
    if ((this.drawerData === null || (this.drawerData && this.drawerData.deviceNumber !== e.target.detail.deviceNumber))) {
      const info = [
        {
          title: '温度(°C)',
          value: '-',
          icon: 'icon-icon_huanjingwendu',
          status: ''
        },
        {
          title: '湿度(%)',
          value: '-',
          icon: 'icon-icon_huanjingshidu',
          status: ''
        },
        {
          title: '降雨量(mm)',
          value: '-',
          icon: 'icon-icon_jiangyuliang',
          status: ''
        },
        {
          title: '气压(pa)',
          value: '-',
          icon: 'icon-icon_qiya',
          status: ''
        },
        {
          title: '风向',
          value: '-',
          icon: 'icon-icon_fengxiang',
          status: ''
        },
        {
          title: 'PM2.5(μg/m³)',
          value: '-',
          icon: 'icon-icon_pm25',
          status: ''
        },
        {
          title: '噪音(db)',
          value: '-',
          icon: 'icon-icon_zaoyin',
          status: ''
        },
        {
          title: '风速(m/s)',
          value: '-',
          icon: 'icon-icon_fengsu',
          status: ''
        },
        {
          title: '负氧离子(个/cm³)',
          value: '-',
          icon: 'icon-icon_fuyanglizi',
          status: ''
        },
        {
          title: 'PM10(μg/m³)',
          value: '-',
          icon: 'icon-icon-pm10',
          status: ''
        },
        {
          title: '二氧化碳(ppm)',
          value: '-',
          icon: 'icon-icon-eryanghuatan',
          status: ''
        },
        {
          title: '光照(Lux)',
          value: '-',
          icon: 'icon-icon-guangzhao',
          status: ''
        },
        {
          title: '太阳总辐射(mwh/cm²)',
          value: '-',
          icon: 'icon-icon-taiyangzongfushe',
          status: ''
        }
      ]
      if (this.drawerData && this.drawerData.deviceNumber !== e.target.detail.deviceNumber) {
        this.drawerClose()
      }
      if (e.target.detail.deviceNumber) {
        this.drawerData = e.target.detail
        // 点击切换大图标
        const iconClick = this.getIcon(e.target.detail.deviceStatus, true)
        if (e.target.detail.longitude && e.target.detail.latitude) {
          e.target.setOffset(new AMap.Pixel(-22, -64))
          e.target.setIcon(iconClick)
        }
        // 温度
        info[0].value = e.target.detail.temperature || '--'
        // 湿度
        info[1].value = e.target.detail.humidity || '--'
        // 降雨量
        info[2].value = e.target.detail.precipitation || '--'
        // 气压
        info[3].value = e.target.detail.pressure || '--'
        // 风向
        info[4].value = directionTransition(Number(e.target.detail.direction)) || '--'
        // PM2.5
        info[5].value = e.target.detail.pm25 || '--'
        // 噪音
        info[6].value = e.target.detail.noise || '--'
        // 风速
        info[7].value = e.target.detail.speed || '--'
        // 负氧离子
        info[8].value = e.target.detail.negativeOxyge || '--'
        // PM10
        info[9].value = e.target.detail.pm10 || '--'
        // 二氧化碳
        info[10].value = e.target.detail.co2 || '--'
        // 光照
        info[11].value = e.target.detail.lux || '--'
        // 太阳总辐射
        info[12].value = e.target.detail.zfs || '--'
        this.detailInfo = info
      }
    }
    this.drawer = true
  }

  getStatus (num: string) {
    const arr = ['正常', '偏高', '偏低']
    return num ? arr[Number(num)] : '--'
  }

  // 获取图片
  getIcon (deviceStatus: string, isCurrent: boolean) {
    if (!isCurrent) {
      return (deviceStatus === '1' ? this.onlineImg : this.offImg)
    } else {
      return (deviceStatus === '1' ? this.onlineImgCursor : this.offImgCursor)
    }
  }

  // 抽屉关闭
  drawerClose () {
    if (this.drawer) {
      this.drawer = false
      // 清空抽屉
      this.iconTab()
      this.drawerData = null
    }
  }

  // 替换图标
  iconTab () {
    if (this.map && this.drawerData) {
      const deviceNumber = this.drawerData.deviceNumber || ''
      const index = this.map.getAllOverlays('marker').findIndex((item: any) => item.detail.deviceNumber === deviceNumber)
      const icon = this.getIcon(this.drawerData.deviceStatus, false)
      const marker = this.map.getAllOverlays('marker')[index] as AMap.Marker
      if (this.drawerData.longitude && this.drawerData.latitude) {
        marker.setOffset(new AMap.Pixel(-18, -48))
        marker.setIcon(icon)
      }
    }
  }
}
